import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { queries } from '../theme/globalStyles'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      groupImage: file(relativePath: { eq: "group.png" }) {
        childImageSharp {
          fluid(maxWidth: 2760, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      businessImage: file(relativePath: { eq: "business.png" }) {
        childImageSharp {
          fluid(maxWidth: 1190, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home - Our Coffee" />
      <Img
        fluid={data.groupImage.childImageSharp.fluid}
        alt="Big Shot Coffee House - Group"
      />
      <h1 style={{ marginTop: 'var(--xl)' }}>
        Locally Roasted and Open for Business!
      </h1>
      <p>
        Our coffee is locally roasted, five miles away at Coffee City USA. About
        as fresh as it gets. It is roasted in small batches, daily. The Master
        Roaster has been roasting coffee for 30+ years, 20 of which have been on
        the same roaster in the same location, at Coffee City USA. It is sourced
        from all over the world, from at least 28 different countries. This
        roasterie was established in 1997 and has been serving expertly roasted
        coffee since the beginning. Here, at Big Shot, we are lucky to have a
        close relationship with this business so we can offer the best of the
        best to my customers.
      </p>
      <LowerWrapper>
        <Img
          fluid={data.businessImage.childImageSharp.fluid}
          alt="Big Shot Coffee House - Business"
        />
        <div>
          <h2>About the Owner</h2>
          <p>
            Hello, my name is Lydia Baskin and I have served in the coffee
            industry for 9 years. I have worked for small locally owned coffee
            shops, large chain commercial coffee shops, coffee roasters, and
            I've run my own private coffee and espresso catering service for the
            past 2.5 years. In all my experience I can say coffee has a unique
            ability to bring out the best in people. Not only that but coffee
            shops are such a safe place to make connections, whether with your
            barista, an old friend, a first date, or a job interview. I have to
            admit, this is what drives me to harbor my own safe space. I want to
            be able to facilitate an environment which will invigorate my local
            area to find deeper connection.
          </p>
        </div>
      </LowerWrapper>
      {/* <CTA>
        <h3>Heading our way?</h3>
        <p>
          Skip the line and order any of our tasty beverages online. Drive-thru
          pick-up is also available for our customers.
        </p>
        <span>
          <Link to="/">Start Your Order</Link>
        </span>
      </CTA> */}
    </Layout>
  )
}

export default IndexPage

const LowerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--xl);
  margin-top: var(--xl);

  ${queries[2]} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--xxl);
  }

  h2 {
    margin-top: 0;
  }

  img {
    margin-bottom: var(--md);
  }
`

// const CTA = styled.aside`
//   border-top: 3px solid var(--beau);
//   margin-top: var(--xl);
//   span > a {
//     background: var(--cream);
//     font-weight: 600;
//     font-size: var(--xs);
//     color: var(--white);
//     text-decoration: none;
//     padding: var(--xs) var(--md);
//     border-radius: var(--xxs);
//     transition: background 0.2s ease-in-out;
//     :hover {
//       background: var(--bistre);
//     }
//   }
// `
